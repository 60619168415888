import styled from 'styled-components'
import {media} from './style'

export function InlineImg({caption, img}: {caption: string; img: string}) {
	return (
		<>
			<ImgDiv img={img} />
			<Caption>{caption}</Caption>
		</>
	)
}

const Caption = styled.p`
	color: grey;
	font-size: 1.3rem;
	margin: 0 auto;
	text-align: center;
	width: 45rem;
	padding-top: 1.5rem;
	padding-bottom: 2.5rem;
	${media.phone`
margin: 0 2rem;
width: auto;
`};
`

const ImgDiv = styled.div<{img: string}>`
	background-size: cover;
	background-image: ${({img}) => `url(${img})`};
	height: 55rem;
	margin: 0 auto;
	width: 55rem;
	${media.phone`
background-position: center;
width: auto;
`};
`
