import styled from 'styled-components'
import {Link as L} from 'react-router-dom'
import {colors, media} from './style'
import pkg from '../package.json'

export function Footer() {
	return (
		<Box>
			<Link to='terms'>Terms and Conditions</Link>
			<Link to='privacy'>Privacy</Link>
			<Version>Version {pkg.version}</Version>
		</Box>
	)
}

const Box = styled.div`
	align-items: center;
	background-color: ${colors.black};
	display: flex;
	height: 6rem;
	margin-top: 7.5rem;
	padding-left: 7.5rem;
	${media.tabPort`
		padding-left: 0;
		justify-content: space-around;
	`};
`
const Link = styled(L)`
	color: white;
	font-size: 1.35rem;
	font-weight: bold;
	margin-right: 5rem;
	text-decoration: none;
	${media.tabPort`
		margin-right: 0;
	`};
`

const Version = styled.h6`
	color: white;
	font-size: 1.35rem;
`
