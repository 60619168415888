import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {SectionHeader} from './component-section-header'
import {StyledLink} from './component-styled-link'
import {Bold, List, Space, Text, Underline} from './component-typography'
import road from './img/case-study-1.jpg'
import koala from './img/case-study-2.jpg'
import hike from './img/case-study-3.jpg'
import img from './img/splash-img.jpg'
import links from './router-links'
import {SplashImg} from './component-splash-img'
import {media} from './style'

export function PageHome() {
	return (
		<>
			<SplashImg
				heading='HV McKay Charitable Trust'
				subheading='Investing in the Community'
				img={img}
			/>
			<SectionHeader heading='Purpose of the Charitable Trust' />
			<Text>
				The H V McKay Charitable Trust was established in 1926 on the death of Hugh Victor McKay.
				Hugh Victor's wish was to provide funding for charitable purposes, particularly:
			</Text>
			<Space />
			<List>
				<Text as='li'>
					The relief of <Bold>poverty</Bold>
				</Text>
				<Text as='li'>
					The advancement of <Bold>education</Bold>
				</Text>
				<Text as='li'>
					The advancement of <Bold>religion</Bold> in accordance with the tenets of the Presbyterian
					church
				</Text>
				<Text as='li'>
					The general benefit of the <Bold>community</Bold> not falling under any of the preceding
					headings
				</Text>
			</List>
			<SectionHeader heading='Projects Funded By The Trust' />
			<RecentProjects />
			<SectionHeader heading='Applications for Assistance' />
			<Text>Funding applications may be submitted for projects meeting these criteria.</Text>
			<Text>
				Please visit the{' '}
				<StyledLink to='/apply'>
					<Underline>Apply</Underline>
				</StyledLink>{' '}
				page for more information.
			</Text>
		</>
	)
}

function RecentProjects() {
	return (
		<GalleryGrid>
			<ImageLink to={links.caseStudy + '/1'}>
				<ImgDivScale>
					<ImgDiv img={road}>
						<OverlayText>Supporting Individuals With Disabilities</OverlayText>
					</ImgDiv>
				</ImgDivScale>
			</ImageLink>
			<ImageLink to={links.caseStudy + '/2'}>
				<ImgDivScale>
					<ImgDiv img={koala}>
						<OverlayText>Digitising Agricultural Records</OverlayText>
					</ImgDiv>
				</ImgDivScale>
			</ImageLink>
			<ImageLink to={links.caseStudy + '/3'}>
				<ImgDivScale>
					<ImgDiv img={hike}>
						<OverlayText>Developing Community Spaces</OverlayText>
					</ImgDiv>
				</ImgDivScale>
			</ImageLink>
		</GalleryGrid>
	)
}

const GalleryGrid = styled.div`
	align-items: center;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	height: 30rem;
	justify-items: center;
	margin: 0 30rem;
	${media.tabLand`
		margin: 0 15rem;
	`};
	${media.tabPort`
		margin: 0;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, 1fr);
		height: 80rem;
		grid-row-gap: 3rem;
	`};
`
const ImgDivScale = styled.div`
	height: 100%;
	overflow: hidden;
`
const ImgDiv = styled.div<{img: string}>`
	align-items: center;
	background-image: ${({img}) =>
		`linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${img})`};
	background-size: cover;
	display: flex;
	height: 100%;
	justify-content: center;
	transition: all 0.25s;
	width: 100%;
	&:hover {
		transform: scale(1.05);
	}
`
const ImageLink = styled(Link)`
	height: 100%;
	text-decoration: none;
	${media.tabPort`
		width: 80%;
	`};
`
const OverlayText = styled.h4`
	color: white;
	font-size: 2.75rem;
	text-align: center;
`
