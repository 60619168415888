import {SplashImg} from './component-splash-img'
import {SectionHeader} from './component-section-header'
import {Text} from './component-typography'
import img from './img/splash-img.jpg'
import {useLocation} from 'react-router-dom'

export function PageCaseStudy() {
	const {pathname} = useLocation()
	const caseStudyID = pathname.substring(pathname.lastIndexOf('/') + 1)
	const {content, imgSubheading} = list[Number(caseStudyID) - 1] || list[0]
	return (
		<>
			<SplashImg heading='Previous Projects' subheading={imgSubheading} img={img} />
			<SectionHeader heading='Outline of the Project' />
			<Text>{content}</Text>
		</>
	)
}

const list = [
	{
		content:
			"H.V. McKay Charitable Trust's support of $20,000 enabled TAD to provide affordable custom equipment for people living with a disability right across NSW. The right equipment provided at the right time can make a huge difference to the future of a person with a disability and their family. The custom equipment that the Charitable Trust provided has, in part, made this difference.",
		imgSubheading: 'Bridging the Gap',
	},
	{
		content:
			'The Charitable Trust provided $25,000 for a scanner to digitise the substantial paper agricultural collection. This work was carried out by volunteers at the Museum of Victoria.',
		imgSubheading: 'Museum of Victoria',
	},
	{
		content:
			'Beolite Village provides independent living homes at affordable cost to elderly rural residents in Mansfield. The creation of an all weather veranda offers an important informal communal meeting place for up to 50 residents. The Charitable Trust provided $30,000 in funding to assist with the cost of development.',
		imgSubheading: 'Beolite Village',
	},
]
