import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {colors} from './style'

export const StyledAnchor = styled.a`
	color: ${colors.black};
`

export const StyledLink = styled(Link)`
	color: ${colors.black};
`
