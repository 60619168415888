import {NavLink as NL, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {colors} from '../style'

export function NavLink({name, to}: {name: string; to: string}) {
	const {pathname} = useLocation()
	return (
		<StyledLink pathname={pathname} to={to}>
			{name}
		</StyledLink>
	)
}

const StyledLink = styled(NL)<{pathname: string}>`
	color: ${colors.grey};
	font-weight: 600;
	padding: 1rem 2rem;
	position: relative;
	text-decoration: none;
	${(p) =>
		p.pathname === p.to &&
		`
		&::after {
			content: '';
			border-bottom: 0.4rem solid ${colors.yellow};
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 5px;
			width: 51%;
		}
	`}
`
