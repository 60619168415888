const links = {
	about: '/about',
	apply: '/apply',
	caseStudy: '/case-study',
	contact: '/contact',
	gallery: '/gallery',
	home: '/',
	privacy: '/privacy',
	submitted: '/submitted',
	notSubmitted: '/not-submitted',
	terms: '/terms',
}

export default links
