import React from 'react'
import styled from 'styled-components'
import {NavLink} from './link'
import hyperlinks from '../router-links'

class Nav extends React.Component {
	render() {
		return (
			<NavDiv>
				<NavLink to={hyperlinks.home} name='Home' />
				<NavLink to={hyperlinks.about} name='About' />
				<NavLink to={hyperlinks.apply} name='Apply' />
				<NavLink to={hyperlinks.gallery} name='Gallery' />
				<NavLink to={hyperlinks.contact} name='Contact' />
			</NavDiv>
		)
	}
}

const NavDiv = styled.div`
	background: white;
	box-shadow: 2px 3px 20px #80808075;
	height: 2rem;
	padding: 2rem;
	position: fixed;
	width: 100vw;
	z-index: 400;
`

export default Nav
