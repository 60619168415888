import styled from 'styled-components'
import {SplashImg} from './component-splash-img'
import img from './img/splash-img.jpg'
import galleryImageA from './img/1.jpg'
import SlideShow from 'react-image-show'

export function PageGallery() {
	return (
		<>
			<SplashImg heading='Gallery' subheading='Photos of the trust' img={img} />
			<Box>
				<SlideShow
					arrows={false}
					fixedImagesHeight
					images={[galleryImageA]}
					imagesHeight='450px'
					imagesHeightMobile='56vw'
					imagesWidth='800px'
					thumbnails
					thumbnailsHeight='12vw'
					thumbnailsWidth='920px'
					width='920px'
				/>
			</Box>
		</>
	)
}

const Box = styled.div`
	margin-top: 7.5rem;
`
