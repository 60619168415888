import hyperlinks from '../router-links'

export const menuItems = [
	{
		label: 'Home',
		to: hyperlinks.home,
	},
	{
		label: 'About',
		to: hyperlinks.about,
	},
	{
		label: 'Apply',
		to: hyperlinks.apply,
	},
	{
		label: 'Gallery',
		to: hyperlinks.gallery,
	},
	{
		label: 'Contact',
		to: hyperlinks.contact,
	},
]
