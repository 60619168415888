import styled from 'styled-components'
import {colors, media} from './style'

export const Bold = styled.span`
	font-weight: 600;
`

export const List = styled.ol`
	margin-left: 2rem;
`

export const Space = styled.div`
	height: 2.5rem;
`

export const Text = styled.p`
	font-size: 1.75rem;
	margin: 0 40rem;
	line-height: 4rem;
	${media.laptopLarge`
		margin: 0 25rem;
	`}
	${media.tabLand`
    	margin: 0 15rem;
  	`};
	${media.tabPort`
    	margin: 0 10rem;
  	`};
	${media.phone`
    	margin: 0 5rem;
  	`};
`

export const Underline = styled.span`
	text-decoration: underline;
	color: ${colors.black};
	&:visited {
		color: red;
	}
`
