import {SectionHeader} from './component-section-header'
import {SplashImg} from './component-splash-img'
import {Text} from './component-typography'
import {StyledAnchor} from './component-styled-link'
import img from './img/splash-img.jpg'

export function PageContact() {
	return (
		<>
			<SplashImg heading='Contact' img={img} />
			<SectionHeader heading='Get in Touch' />
			<Text>
				The trustees of the Trust may be contacted in writing through our secretariat: Kate Raidal,
				Henry Partners, PO Box 509, Mansfield VIC 3724 or email{' '}
				<StyledAnchor href='mailto:admin@henrypartners.com.au'>
					admin@henrypartners.com.au
				</StyledAnchor>
			</Text>
		</>
	)
}
