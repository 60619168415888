import {css} from 'styled-components'

export const colors = {
	black: '#171717',
	grey: '#4d4d4d',
	yellow: '#FFEE00',
	white: '#fff',
}

export const media = {
	// 0px - 425px
	phoneSmall: (...args: Parameters<typeof css>) => css`
		@media (min-width: 0) and (max-width: 26.5625em) {
			${css(...args)};
		}
	`,
	// 426px - 600px
	phone: (...args: Parameters<typeof css>) => css`
		@media (max-width: 37.5em) {
			${css(...args)};
		}
	`,
	// 601px - 900px
	tabPort: (...args: Parameters<typeof css>) => css`
		@media (max-width: 56.25em) {
			${css(...args)};
		}
	`,
	// 901px - 1024px
	tabLand: (...args: Parameters<typeof css>) => css`
		@media (max-width: 64em) {
			${css(...args)};
		}
	`,
	// 1025px - 1440px
	laptopLarge: (...args: Parameters<typeof css>) => css`
		@media (max-width: 90em) {
			${css(...args)};
		}
	`,

	// 1441px - 1800px - Normal

	// 1801px - 2400px
	desktopBig: (...args: Parameters<typeof css>) => css`
		@media (min-width: 112.5em) and (max-width: 149.9375) {
			${css(...args)};
		}
	`,
	// 2400px - infinite
	fourK: (...args: Parameters<typeof css>) => css`
		@media (min-width: 150em) {
			${css(...args)};
		}
	`,
}
