import {SectionHeader} from './component-section-header'
import {SplashImg} from './component-splash-img'
import {List, Text, Underline} from './component-typography'

import img from './img/splash-img.jpg'

export function PageApply() {
	return (
		<div>
			<SplashImg
				heading='Applications'
				subheading='Improve your community'
				img={img}
			/>
			<SectionHeader heading='Eligibility' />
			<Text>
				The HV McKay Charitable Trust can only accept applications that meet the
				following criteria:
			</Text>
			<List>
				{criteria.map((text) => (
					<Text as='li' key={text}>
						{text}
					</Text>
				))}
			</List>
			<SectionHeader heading='Guidelines' />
			<Text>
				The HV McKay Charitable Trust focuses on funding projects that meet the
				following guidelines:
			</Text>
			<List>
				{guidelines.map((text) => (
					<Text as='li' key={text}>
						{text}
					</Text>
				))}
			</List>
			<SectionHeader heading='How to Apply' />
			<Text>
				The application process is comprised of 12 sections. It should take about 20
				minutes to complete. Email the completed form to{' '}
				<em>admin@henrypartners.com.au</em>
			</Text>
			<Text>
				<a href='https://firebasestorage.googleapis.com/v0/b/hv-mckay-trust.appspot.com/o/Application%20Form.doc?alt=media&token=e8cb0a6e-c050-4431-9853-c24cec8dda5e'>
					<Underline>Download the application</Underline>
				</a>
			</Text>
		</div>
	)
}

const criteria = [
	'Applicant must be Deductible Gift Recipients (DGRs).',
	'Applicant must registered with the Australian Charities and Not-For-Profits Commission (ACNC).',
	'Applicant must be eligible to receive Charity Tax Concessions (CTC).',
]

const guidelines = [
	'Focus is on rural as opposed to urban projects. Support may also be given to national or State- wide bodies that have a rural bias. Interstate rural projects will also be considered.',
	'Projects will be funded particularly where more orthodox support is hampered by conditions. Funding for feasibility or development stage of a project may be offered.',
	'Precise and plausible programs, budget and funding information will assist in the consideration of applications. General requests are unhelpful.',
	'Applications should indicate who will benefit from the project, the long term benefits to society and should indicate how the success of the project will be measured.',
	'Applications should indicate staff and other personnel required for the project, time scale, and the involvement of the organisation and its membership.',
	'Funding will be considered to organisations which are community based and managed and can demonstrate accountability to the membership and the wider community.',
	'Funding will only be considered for applications which are non profit and are legally constituted.',
	'Projects which are unlikely to obtain government funding at a given stage of development will be considered favourably.',
]
