import React from 'react'
import Desktop from './desktop'
import Mobile from './mobile'

const mobileBreakpoint = 760

class Nav extends React.Component {
	state = {width: 0, height: 0}

	componentDidMount() {
		this.updateWindowDimensions()
		window.addEventListener('resize', this.updateWindowDimensions)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions)
	}

	updateWindowDimensions = () => {
		this.setState({width: window.innerWidth, height: window.innerHeight})
	}

	render() {
		return this.state.width >= mobileBreakpoint ? <Desktop /> : <Mobile />
	}
}

export default Nav
