import {InlineImg} from './component-inline-img'
import {SectionHeader} from './component-section-header'
import {SplashImg} from './component-splash-img'
import {StyledLink} from './component-styled-link'
import {Space, Text, Underline} from './component-typography'
import imgPortrait from './img/hv-mckay-a.png'
import img from './img/splash-img.jpg'
import imgInline from './img/square-img.jpg'
import links from './router-links'

export function PageAbout() {
	return (
		<>
			<SplashImg heading='History' subheading='The Life of HV McKay' img={img} />
			<SectionHeader heading='Early Life' />
			<Text>
				Hugh Victor McKay was raised near Drummartin in country Victoria. His parents were Irish
				immigrants who had arrived in Victoria in 1852. His father had been a stonemason and then a
				miner before becoming a farmer around the end of 1865. Having attended primary school and
				receiving additional education from his brother Nathaniel, Hugh Victor returned to the
				family farm at 13. In 1883 he read about the Californian Combine harvester and was inspired
				to build a prototype stripper-harvester with his brother John and his father. The Sunshine
				Harvester was patented on 24 March 1885, commercialised and built under contract in
				Melbourne and Bendigo. It revolutionised wheat harvesting and was subsequently sold around
				the world.
			</Text>
			<SectionHeader heading='Production' />
			<InlineImg
				img={imgInline}
				caption='Farmers Visit to Sunshine Harvester Works (formerly Braybrook Implement Works), Sunshine, Victoria, circa 1910.'
			/>
			<Text>
				In 1888 H. V. McKay opened manufacturing works in Ballarat, eventually moving to Braybrook
				Junction near Melbourne in 1906. The Sunshine Harvester Works produced a wide range of
				agricultural equipment and was entirely self-sufficient; everything from the rivets, nuts
				and bolts right up to the supervisors' bicycles was manufactured on site. At its peak the
				plant covered more than 30 acres and employed nearly 3000 workers.
			</Text>
			<Space />
			<Text>
				In 1907 the town's name was changed to Sunshine in recognition of the importance of the
				industrial works. McKay implemented many projects in the town, including public buildings,
				gardens, a church, a school and library. Although strongly opposed to radical unionism, he
				respected and supported his workers, organising access to interest free loans for the
				purpose of buying homes.
			</Text>
			<SectionHeader heading='Legacy' />
			<InlineImg
				img={imgPortrait}
				caption='H. V. McKay Massey Harris, Portrait of Hugh Victor McKay, Victoria, circa 1900s'
			/>
			<Text>
				After a lifetime of business success built on personal industry and determination, Hugh
				Victor McKay died at Rupertswood on 21 May 1926. He left a codicil vesting the income from
				100,000 shares in the H. V. McKay Charitable Trust, chaired by George Swinburne and designed
				to encourage rural settlement, improve country life, and assist charitable objects at
				Sunshine.
			</Text>
			<Space />
			<Text>
				If you think that you have a project that might meet these criteria, please visit the{' '}
				<StyledLink to={links.apply}>
					<Underline>Apply</Underline>
				</StyledLink>{' '}
				page for more information.
			</Text>
		</>
	)
}
