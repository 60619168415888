import React from 'react'
import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import {transparentize} from 'polished'
import {menuItems} from './menu-items'
import {colors} from '../style'

class Mobile extends React.Component {
	state = {checked: false}
	menuToggle = () => {
		this.setState({checked: !this.state.checked})
	}
	mapMenu = () => {
		return menuItems.map((item, i) => {
			const removeZeroIndex = (index: number) => index + 1
			const formatNumber = (index: number) =>
				index < 9 ? `0${removeZeroIndex(index)}` : removeZeroIndex(index)
			return (
				<ListItem key={item.label}>
					<StyledNavLink to={item.to} onClick={this.menuToggle}>
						<span>{formatNumber(i)}</span>
						{item.label}
					</StyledNavLink>
				</ListItem>
			)
		})
	}
	render = () => {
		return (
			<>
				<HiddenCheckbox checked={this.state.checked} onChange={this.menuToggle} />
				<FixedButton>
					<Icon>&nbsp;</Icon>
				</FixedButton>
				<Background>&nbsp;</Background>
				<NavWrapper>
					<List>{this.mapMenu()}</List>
				</NavWrapper>
			</>
		)
	}
}

const Background = styled.div`
	background-image: radial-gradient(${colors.black}, ${colors.black});
	border-radius: 50%;
	height: 6rem;
	position: fixed;
	right: 3.5rem;
	top: 3.5rem;
	transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
	width: 6rem;
	z-index: 1000;
`

const FixedButton = styled.label.attrs({
	htmlFor: 'navi-toggle',
})`
	background-color: white;
	box-shadow: 0 1rem 3rem ${transparentize(0.8, colors.black)};
	cursor: pointer;
	height: 7rem;
	position: fixed;
	right: 3rem;
	text-align: center;
	top: 3rem;
	width: 7rem;
	z-index: 2000;
`

const Icon = styled.span`
	margin-top: 3.25rem;
	position: relative;
	&,
	&::before,
	&::after {
		background-color: ${colors.black};
		display: inline-block;
		height: 2px;
		width: 3rem;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		left: 0;
		transition: all 0.2s;
	}

	&::before {
		top: -1.15rem;
	}
	&::after {
		top: 1.15rem;
	}

	${FixedButton}:hover &::before {
		top: -1rem;
	}

	${FixedButton}:hover &::after {
		top: 1rem;
	}
`

const List = styled.ul`
	position: absolute;
	left: 50%;
	list-style-type: none;
	text-align: center;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
`

const ListItem = styled.li`
	margin: 1rem;
`

const NavWrapper = styled.nav`
	height: 100vh;
	position: fixed;
	opacity: 0;
	right: 0;
	transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	top: 0;
	width: 0;
	z-index: 1500;
`

const StyledNavLink = styled(NavLink)`
	&:link,
	&:visited {
		background-image: linear-gradient(120deg, transparent 0%, transparent 50%, ${colors.white} 50%);
		background-size: 250%;
		color: ${colors.white};
		display: inline-block;
		font-size: 3rem;
		font-weight: 300;
		padding: 1rem 2rem;
		text-decoration: none;
		text-transform: uppercase;
		transition: all 0.4s;

		span {
			display: inline-block;
			margin-right: 1.5rem;
		}
	}

	&:hover,
	&:active {
		background-position: 100%;
		transform: translateX(1rem);
		color: ${colors.black};
	}
`

/* IMPORTANT: Keep last!
 * HiddenCheckbox alters several components through the :checked psuedo class.
 * These components must be defined above HiddenCheckbox for this functionality to work. */
const HiddenCheckbox = styled.input.attrs({
	type: 'checkbox',
	id: 'navi-toggle',
})`
	display: none;
	&:checked ~ ${Background} {
		transform: scale(50);
	}
	&:checked ~ ${NavWrapper} {
		opacity: 1;
		width: 100%;
	}
	&:checked ~ ${FixedButton} > ${Icon} {
		background-color: transparent;
	}
	&:checked ~ ${FixedButton} > ${Icon}::before {
		top: 0;
		transform: rotate(135deg);
	}
	&:checked ~ ${FixedButton} > ${Icon}::after {
		top: 0;
		transform: rotate(-135deg);
	}
`

export default Mobile
