import React from 'react'
import {createRoot} from 'react-dom/client'
import {RouterApp} from './router-app'
import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'
import reportWebVitals from './reportWebVitals'
import './index.css'

Sentry.init({
	dsn: 'https://f2dbb5dbd7694a5d83d8b7f28b12b6a8@o1243072.ingest.sentry.io/6398118',
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
})

createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<RouterApp />
	</React.StrictMode>
)

reportWebVitals()
