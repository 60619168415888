import styled from 'styled-components'
import {colors, media} from './style'

export function SplashImg({
	heading,
	img,
	subheading,
}: {
	heading: string
	img: string
	subheading?: string
}) {
	return (
		<SplashDiv img={img}>
			<Heading>{heading}</Heading>
			<SubHeading>{subheading}</SubHeading>
		</SplashDiv>
	)
}

const Heading = styled.h1`
	color: ${colors.white};
	font-size: 7.5rem;
	padding-bottom: 1rem;
	${media.phone`
		font-size: 6rem;
	`}
`
const SplashDiv = styled.div<{img: string}>`
	align-items: center;
	background-image: ${({img}) =>
		`linear-gradient(
	rgba(20, 20, 20, 0.5),
	rgba(20, 20, 20, 0.5)
	),
	url(${img})`};
	background-size: cover;
	display: flex;
	flex-direction: column;
	height: 50rem;
	justify-content: center;
	padding-top: 2rem;
	${media.tabPort`
		padding: 3rem;
		text-align: center;
	`};
`
const SubHeading = styled.h2`
	color: ${colors.white};
	font-size: 3.5rem;
	${media.tabPort`
		margin-top: 1.5rem;
	`};
`
