import {useEffect} from 'react'
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom'
import {Footer} from './component-footer'
import Nav from './nav'
import {PageAbout} from './page-about'
import {PageApply} from './page-apply'
import {PageCaseStudy} from './page-case-study'
import {PageContact} from './page-contact'
import {PageGallery} from './page-gallery'
import {PageHome} from './page-home'
import {PagePrivacy} from './page-privacy'
import links from './router-links'

export function RouterApp() {
	return (
		<BrowserRouter>
			<Nav />
			<ResetScroll>
				<Routes>
					<Route element={<PageAbout />} path={links.about} />
					<Route element={<PageApply />} path={links.apply} />
					<Route element={<PageCaseStudy />} path={links.caseStudy + '/*'} />
					<Route element={<PageContact />} path={links.contact} />
					<Route element={<PageGallery />} path={links.gallery} />
					<Route element={<PageHome />} path={links.home} />
					<Route element={<PagePrivacy />} path={links.privacy} />
					<Route element={<div>Submitted</div>} path={links.submitted} />
					<Route element={<div>Not Submitted</div>} path={links.notSubmitted} />
					<Route element={<PagePrivacy />} path={links.terms} />
				</Routes>
			</ResetScroll>
			<Footer />
		</BrowserRouter>
	)
}

function ResetScroll({children}: Props) {
	const {pathname} = useLocation()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])
	return children
}
