import styled from 'styled-components'
import {colors, media} from './style'

export function SectionHeader({heading}: {heading: string}) {
	return (
		<Section>
			<Heading>{heading}</Heading>
		</Section>
	)
}

const Heading = styled.h2`
	border-bottom: 0.5rem solid ${colors.yellow};
	color: ${colors.grey};
	display: inline-block;
	font-size: 3rem;
	${media.phone`
		font-size: 2.5rem;
	`};
`
const Section = styled.div`
	display: flex;
	padding: 7.5rem 0 3rem 0;
	justify-content: center;
`
